import algoliasearch from "algoliasearch"

export const algoliaSearch = () => {
  const config = useRuntimeConfig()
  const algoliaClient = algoliasearch(
    config.public.algolia.applicationId,
    process.server ? config.algolia.ssrApiKey : config.public.algolia.apiKey
  )
  const indicesNames = {
    products: `TOP_PRODUCTS`,
    brands: `TOP_BRANDS`
  }

  return {
    client: algoliaClient,
    indices: {
      products: algoliaClient.initIndex(indicesNames.products),
      brands: algoliaClient.initIndex(indicesNames.brands)
    }
  }
}
