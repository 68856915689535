import { CookieOptions } from "#app"
import destr from "destr"

const cookieDecode = (val: any) => {
  try {
    return destr(decodeURIComponent(val))
  } catch {
    return null // or undefined if that makes more sense in your app
  }
}

const cookieEncode = (val: any) =>
  encodeURIComponent(typeof val === "string" ? val : JSON.stringify(val))

const cookieOptions = {
  watch: process.client,
  decode: cookieDecode,
  encode: cookieEncode
}

export const useStatefulCookie = <T>(name: string, options?: CookieOptions) => {
  const cookie = useCookie<T | null>(name, { ...cookieOptions, ...options })
  const state = useState(`cookies:${name}`, () => cookie.value)

  const statefulCookie = computed({
    get() {
      return process.client ? cookie.value : state.value
    },
    set(value) {
      state.value = value
      cookie.value = value
    }
  })

  return statefulCookie
}
